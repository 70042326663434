import { useEffect, useState } from "react";
import Disciplina from "../../../types/disciplina.type";
import Component from "../../components";
import { useForm } from "react-hook-form";
import { DisciplinaInputProps, disciplinaSchema } from "../../../schemas/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import Curso from "../../../types/curso.type";
import api from "../../../services/api";
import { FaTrash } from "react-icons/fa";


const DisciplinaContent = () => {
    const [listDisp, setListDisp] = useState<Disciplina[]>([]);
    const [listCursos, setListCursos] = useState<Curso[]>([]);
    const [curso, setCurso] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sucess, setSucess] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<DisciplinaInputProps>({ resolver: zodResolver(disciplinaSchema) })

    useEffect(() => {
        setLoading(true);
        (async () => {

            await api
                .post("ListarCursos", {
                    usuario: localStorage.getItem("login"),
                    senha: localStorage.getItem("senha"),
                })
                .then((resp) => {
                    setListCursos(resp.data);
                    setLoading(false);
                });
        })();
    }, []);


    const Atualizar = async () => {
        await api
            .post("ListarDisciplinasC", {
                login: localStorage.getItem("login"),
                senha: localStorage.getItem("senha"),
                CursoId: curso,
            })
            .then((resp) => {
                setListDisp(resp.data);
                setLoading(false);

            });
    }

    const trocaCurso = async (id: string) => {
        setLoading(true);
        let curso = Number(id);
        console.log(curso);
        api
            .post("ListarDisciplinasC", {
                CursoId: curso,
                login: localStorage.getItem("login"),
                senha: localStorage.getItem("senha"),
            })
            .then((resp) => {
                setListDisp(resp.data);
                setLoading(false);

            });
        setCurso(curso);
    }


    const onSubmit = async (data: DisciplinaInputProps) => {
        setLoading(true);

        const updatedData = {
            ...data,
            login: localStorage.getItem("login"),
            senha: localStorage.getItem("senha")
        };


        await api
            .post("AdicionarDisciplina", updatedData)
            .then((resp) => {
                setLoading(false);
                setSucess(true);
                setTimeout(() => {
                    setSucess(false);
                    setValue('nome', "");
                    Atualizar();
                }, 1500);

            }).catch(error => {

            })
    }

    const handleDelete = async (itemId: number) => {
        setLoading(true);
        try {
            api
                .post("ExcluirDisciplina", {
                    id: itemId
                })
                .then((resp) => {
                    const listDisp2 = listDisp.filter(a => a.id !== itemId);
                    setListDisp(listDisp2);
                    setLoading(false);
                });
            // Após a exclusão bem-sucedida, você pode remover o item da lista local
            setListDisp(prevList => prevList.filter(item => item.id !== itemId));
        } catch (error) {
            console.error("Erro ao excluir o item:", error);
            // Você pode adicionar mais tratamentos de erro ou mostrar uma mensagem para o usuário aqui
        }
    }

    return (
        <form className="" onSubmit={handleSubmit(onSubmit)}>
            {loading && <Component.Shared.LoaderComponent overlay />}

            <h5>Disciplinas do curso</h5>
            {listDisp.length > 0 ? <table className="table mt-2 table-bordered">
                <thead>

                </thead>
                <tbody>
                    {listDisp.map((item, key) => {
                        return (
                            <tr key={item.id} style={{height:'auto'}}>
                                <td>
                                    {item.nome}
                                </td>
                                <td className="text-center align-middle">
                                    <FaTrash  onClick={() => handleDelete(item.id)} style={{ cursor: 'pointer' }} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table> :
                <Component.Shared.TextComponent value='Não existe nenhuma disciplina para o curso. Cadastre agora' size={12} color='red' margin={0} align='flex-start' />
            }

            <h5>Criar uma nova disciplina</h5>
            <label htmlFor="name2">Curso</label>
            <select
                defaultValue=""
                {...register('cursoid')}
                onChange={(e) => trocaCurso(e.target.value)}
                className="form-control"
            >
                <option value="" disabled>
                    Selecione um curso
                </option>
                {listCursos ? listCursos.map((item, i) => {
                    return (
                        <option
                            key={item.id.toString()}
                            value={item.id}

                        >
                            {item.nome}{" "}
                        </option>
                    );
                }) : null}
            </select>

            {errors.cursoid ? (
                <Component.Shared.TextComponent value={errors.cursoid.message} size={12} color='red' margin={0} align='flex-start' />
            ) : null}


            <label htmlFor="name">Nome</label>
            <Component.Shared.TextInputComponent size={16} margin={0} color="black" register={register('nome')} placeholder="* Nome" type="text" />

            {errors.nome ? (
                <Component.Shared.TextComponent value={errors.nome.message} size={12} color='red' margin={0} align='flex-start' />
            ) : null}






            {sucess ? (
                <Component.Shared.TextComponent value={'Registro salvo com sucesso.'} size={12} color='green' margin={0} align='flex-start' />
            ) : null}

            <button className="btn btn-primary mt-2 mb-2" type='submit'>Adicionar</button>
        </form>
    );
}

export default DisciplinaContent;