import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css';

import {

  createBrowserRouter,
 
  RouterProvider
} from 'react-router-dom'

import NotFound from './pages/NotFound'
import AuthProvider from './context/auth.provider'
import Login from './pages/login/login'
import CreateAccount from './pages/create-account/create-account';
import ForgotPassword from './pages/forgot-password/forgot';
import Home from './pages/home/home';
import Component from './components/components';
import Recovery from './pages/recovery/recovery';


const routes = createBrowserRouter([
  {
    path: '/home',
    element: <Home />,
    children: [
      { path: '/home', element: <Component.Custom.HomeContent /> },
      { path: 'instituicoes', element: <Component.Custom.InstituicaoContent /> },
      { path: 'cursos', element: <Component.Custom.CursoContent /> },
      { path: 'disciplinas', element: <Component.Custom.DisciplinaContent /> },
      { path: 'gerarprovas', element: <Component.Custom.GerarProvaContent /> },
      { path: 'bancoquestoes', element: <Component.Custom.BancoQuestoesContent /> },
      { path: 'questoes', element: <Component.Custom.QuestaoContent /> }
    ]
  },
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: '/create-account',
        element: <CreateAccount />
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />
      },
      {
        path: '/recovery',
        element: <Recovery />
      },
    ]
  }
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={routes} />
      </AuthProvider>
  </React.StrictMode>
)