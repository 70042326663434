
export const isValidCPF = (value: string) => {
    const cleaned = value.replace(/\D/g, ''); // Remove caracteres não numéricos
  
    if (cleaned.length !== 11) {
      return false;
    }
  
    const num1 = parseInt(cleaned.charAt(0));
    const num2 = parseInt(cleaned.charAt(1));
    const num3 = parseInt(cleaned.charAt(2));
    const num4 = parseInt(cleaned.charAt(3));
    const num5 = parseInt(cleaned.charAt(4));
    const num6 = parseInt(cleaned.charAt(5));
    const num7 = parseInt(cleaned.charAt(6));
    const num8 = parseInt(cleaned.charAt(7));
    const num9 = parseInt(cleaned.charAt(8));
    const num10 = parseInt(cleaned.charAt(9));
    const num11 = parseInt(cleaned.charAt(10));
  
    if (
      num1 === num2 &&
      num2 === num3 &&
      num3 === num4 &&
      num4 === num5 &&
      num5 === num6 &&
      num6 === num7 &&
      num7 === num8 &&
      num8 === num9 &&
      num9 === num10 &&
      num10 === num11
    ) {
      return false;
    } else {
      const soma1 =
        num1 * 10 +
        num2 * 9 +
        num3 * 8 +
        num4 * 7 +
        num5 * 6 +
        num6 * 5 +
        num7 * 4 +
        num8 * 3 +
        num9 * 2;
  
      let resto1 = (soma1 * 10) % 11;
      if (resto1 === 10) {
        resto1 = 0;
      }
  
      const soma2 =
        num1 * 11 +
        num2 * 10 +
        num3 * 9 +
        num4 * 8 +
        num5 * 7 +
        num6 * 6 +
        num7 * 5 +
        num8 * 4 +
        num9 * 3 +
        num10 * 2;
  
      let resto2 = (soma2 * 10) % 11;
      if (resto2 === 10) {
        resto2 = 0;
      }
  
      return resto1 === num10 && resto2 === num11;
    }
  };
