import React, { useEffect, useState } from 'react';
import "./index.css"
import { Outlet } from 'react-router-dom';
import Component from "../../components/components"

const Home: React.FC = () => {
    const [title, setTitle] = useState('Início');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='app'>
            {windowWidth <= 500 ? (
                <Component.Custom.SideBarMobile onMenuItemClick={setTitle} />
            ) : (
                <Component.Custom.SiderBarComponent onMenuItemClick={setTitle} />
            )}
            <Component.Custom.HeaderComponent title={title}  />
            <Component.Custom.ContentComponent >
              <Outlet/>
            </Component.Custom.ContentComponent>
            <Component.Custom.FooterComponent />
        </div>
    );
};

export default Home;