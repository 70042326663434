import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { bancoquestoesSchema, BancoQuestoesInputProps } from '../../schemas/schemas';
import Component from '../components';
import api from '../../services/api';
import Questao from '../../types/questao.type';
import { Dificuldade } from '../../types/dificuldade.type';


interface QuestaoItemProps {
    quest: Questao; // Defina a estrutura de 'Questao' conforme necessário
    lstDificuldade: Dificuldade[]; // Defina a estrutura de 'Dificuldade' conforme necessário
    disciplinaId: number | null;
    onQuestaoAdded?: (quest: Questao) => void;
}

const QuestaoItem: React.FC<QuestaoItemProps> = ({ quest, lstDificuldade, disciplinaId, onQuestaoAdded }) => {
    const { register, handleSubmit, formState: { errors } } = useForm<BancoQuestoesInputProps>({
        resolver: zodResolver(bancoquestoesSchema)
    });

    const [sucess, setSucess] = useState(false);
    const [error, setError] = useState(false);
    const onSubmit = (data: BancoQuestoesInputProps) => {

        const updatedQuest = {
            ...quest,
            nivel: data.nivel,
            bimestre: data.bimestre,
            disciplinaId: disciplinaId,
        };

        api.post("AnexarQuestao", updatedQuest).then((resp) => {
            setSucess(true);
            setTimeout(() => {
                onQuestaoAdded && onQuestaoAdded(quest);
            }, 1500);
        }).catch(error => {
             setError(true);
        })
    };

    return (

        <tr >
            <td>
                <form onSubmit={(e) => handleSubmit(onSubmit)(e)}>
                    {quest.imagem !== null && <img src={`data:image/jpeg;base64,${quest.imagem}`} className="imgbanco" alt="Imagem" />}
                    <br></br>
                    <p>{quest.texto}</p>
                    {quest.listaRespostas && Array.isArray(quest.listaRespostas) ? (
                        <ul >
                            {quest.listaRespostas.map((resposta, i) => (
                                <li key={i}>{resposta.alternativa} ) {resposta.texto}</li>
                            ))}
                        </ul>
                    ) : (
                        <p>Não há respostas para esta questão.</p>
                    )}
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="name">Nível de dificuldade</label>
                            <select
                                defaultValue=""
                                {...register('nivel')}
                                className="form-control"
                            >
                                <option value="" disabled>
                                    Selecione uma dificuldade
                                </option>
                                {lstDificuldade.map((item, i) => {
                                    return (
                                        <option
                                            key={item.id.toString()}
                                            value={item.id.toString()}

                                        >
                                            {item.descricao}{" "}
                                        </option>
                                    );
                                })}
                            </select>
                            {errors.nivel ? (
                                <Component.Shared.TextComponent value={errors.nivel.message} size={12} color='red' margin={0} align='flex-start' />
                            ) : null}
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="name">Bimestre</label>
                            <input
                                {...register('bimestre')}
                                type="number"
                                className="form-control"
                                placeholder="Informe o bimestre"
                            />
                            {errors.bimestre ? (
                                <Component.Shared.TextComponent value={errors.bimestre.message} size={12} color='red' margin={0} align='flex-start' />
                            ) : null}
                        </div>
                    </div>

                    <button className="btn btn-primary mt-2 mb-2" type="submit" >Anexar</button>
                    {error ? (
                        <Component.Shared.TextComponent value={"Ocorreu um erro ao anexar a questão. Tente novamente."} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}
                    {sucess ? (
                        <Component.Shared.TextComponent value={"Inserido com sucesso"} size={12} color='green' margin={0} align='flex-start' />
                    ) : null}
                    <br></br>
                </form>
            </td>
        </tr>

    );
};

export default QuestaoItem;