import React from "react";

interface ImagePros {

  height: number;
  source: string,
  width: number;
  className: string;
  margin: number;
}

const ImageComponent: React.FC<ImagePros> = ({

  height,
  source,
  width,
  className,
  margin
}) => {
  return (
    <img
    className={className}
      src={process.env.PUBLIC_URL + source}
      style={{
        height: height,
        width: width,
        margin: margin,
      
      
      }}
    >
    </img>
  );
}

export default ImageComponent;