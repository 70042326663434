
export interface TipoQuestao {
    id: boolean;
    descricao: string;
    }
    
  const list: TipoQuestao[] = [
    {
      id: false, descricao: "Dissertativa"
     },
     {
      id: true, descricao: "Múltipla escolha"
     }];
    
    export default list;