import axios from "axios";

export const BASE_URL = "https://sistema.consultoriamello.com.br/";


const api = axios.create({
  baseURL: "https://sistema.consultoriamello.com.br/"
});

api.interceptors.request.use(async (config : any)=> {
  const token = localStorage.getItem("token")
 
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


export default api;