import React from 'react';
import './index.css';
import Component from '../../components';


type HeaderProps = {

  title: string

}

const HeaderComponent = (props: HeaderProps) => {
  return (
    <div className="header">
      <Component.Shared.TextComponent value={props.title} size={22} color='black' margin={0} align='flex-start' />
    </div>
  );
}

export default HeaderComponent;