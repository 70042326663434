import React from "react";

interface TextProps {
  color: string,
  size: number,
  value: string | undefined;
  margin: number,
  align: 'center' | 'flex-end' | 'flex-start' | 'top',
}

const TextComponent: React.FC<TextProps> = ({
  color,
  size,
  value,
  margin,
  align
}) => {
  return (
    <p
      style={{
        color: color,
        fontSize: size,
        margin: margin ? margin : 0,
        alignSelf:  align ? align : 'center',
        fontWeight: "600",


      }}
    >
      {value}
    </p>
  );
}

export default TextComponent;