import React from 'react';
import './index.css';
import Component from '../../components';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaDatabase, FaFile, FaHome, FaMinus, FaPlus } from 'react-icons/fa';
const SiderBarComponent: React.FC<{ onMenuItemClick: (title: string) => void }> = ({ onMenuItemClick }) => {

  const navigate = useNavigate();
  return (
    <div className="sidebar">
      <div className="logo">
        <Component.Shared.ImageComponent height={150} width={150} className='' source={process.env.PUBLIC_URL + '/logo.png'} margin={0} />
        <p>Inova Provas</p>
      </div>
     
      <ul className="menu-list">
        <li className="menu-item" onClick={() => { navigate('/home'); onMenuItemClick('Página Inicial'); }}>
          <FaHome size={15} /> Página Inicial
        </li>

        <li className="menu-item has-submenu">
          <FaPlus size={15} /> Cadastros
          <ul className="submenu-list">
            <li className="submenu-item" onClick={() => { navigate('instituicoes'); onMenuItemClick('Instituições'); }}> <FaMinus size={15} /> Instituições</li>
            <li className="submenu-item" onClick={() => { navigate('cursos'); onMenuItemClick('Cursos'); }}><FaMinus size={15} /> Cursos</li>
            <li className="submenu-item" onClick={() => { navigate('disciplinas'); onMenuItemClick('Disciplinas'); }}><FaMinus size={15} /> Disciplinas</li>
            <li className="submenu-item" onClick={() => { navigate('questoes'); onMenuItemClick('Questões'); }}><FaMinus size={15} /> Questões</li>
          </ul>
        </li>

        <li className="menu-item" onClick={() => { navigate('gerarprovas'); onMenuItemClick('Geração de provas'); }}><FaFile size={15} /> Geração de provas</li>
        <li className="menu-item" onClick={() => { navigate('bancoquestoes'); onMenuItemClick('Banco de questões'); }}><FaDatabase size={15} /> Banco de questões</li>
      </ul>

    </div>
  );
}

export default SiderBarComponent;