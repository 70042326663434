import { useContext } from 'react'
import { AuthContext } from './auth.provider'


const useAuthStore = () => {
  const { isAuthenticated, user, error, create , login, logout, forgot, recovery, clearError} =
    useContext(AuthContext)
  return { isAuthenticated, user, error, create , login, logout, forgot,recovery, clearError }
}

export default useAuthStore