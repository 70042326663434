import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { createContext, ReactNode, useEffect, useState } from 'react'

import api from '../services/api'
import { ForgotInputProps, LoginInputProps, RecoveryInputProps, RegisterInputProps } from '../schemas/schemas'
import UserProps from '../types/user.type'


interface AuthContextProps {
  isAuthenticated: boolean
  user: UserProps | null
  error: null | string
  create: (data: RegisterInputProps) => Promise<string>
  login: (data: LoginInputProps) => Promise<boolean>
  logout: () => void
  forgot: (data: ForgotInputProps) => Promise<boolean>,
  recovery: (data: RecoveryInputProps) => void,
  clearError: () => void
}

export const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  user: null,
  error: null,
  create: async () => '',
  login: async () => false,
  logout: () => { },
  forgot: async () => false,
  recovery: async () => { },
  clearError: () => { }
})

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<UserProps | null>(null)
  const [error, setError] = useState<string | null>(null)

  const create = async (data: RegisterInputProps): Promise<string> => {
    let errormsg = '';
    console.log(data);
    await api
      .post('CriarCadastro', data)
      .then(response => {

      })
      .catch(error => {
        setError(error.response.data.message)
        errormsg = error.response.data.message;

      })

    return errormsg;
  }

  const login = async (data: any): Promise<boolean> => {

    let auth = false;

    await api
      .post('security/createToken', data)
      .then(response => {

        localStorage.setItem("token", response.data);
        localStorage.setItem("senha", data.senha);
        localStorage.setItem("login", data.login);

        setIsAuthenticated(true)
        auth = true;

      })
      .catch(error => {
        setError("Usuário ou senha inválidos");
        console.log(error);
        auth = false;
      })
    return auth;
  }


  const forgot = async (data: any): Promise<boolean> => {

    let auth = false;

    await api
      .post('EsqueceuSenha', data)
      .then(response => {
        auth = true;

      })
      .catch(error => {
        setError("Email não cadastrado");
        auth = false;
      })
    return auth;
  }

  const logout = async () => {
    try {
      Cookies.remove('AccessToken')
      setUser(null)
      setIsAuthenticated(false)
    } catch (error) {
      console.error(error)
    }
  }
  const recovery = async (data: any): Promise<void> => {

    await api
      .post('recuperarsenha2', data)
      .then(response => {


      })
      .catch(error => {
        setError("Email não cadastrado");

      })

  }

  const clearError = async () => {
    try {
      setError(null);
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode<UserProps>(token)
      if (decoded.exp * 1000 < Date.now()) {
        setUser(null)
        setIsAuthenticated(false)
        localStorage.setItem("token", "");
      }
      else {
        setUser(decoded)
        setIsAuthenticated(true)
      }


    }
  }, [])

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, error, create, login, logout, forgot, recovery, clearError }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider