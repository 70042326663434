

const HomeContent = () => {
    return (
        <div >
            <p > Olá, Seja bem vindo! </p>

            <b>Cadastros</b>
            <p >
                Se você ainda não tiver <b>DISCIPLINAS</b> e <b>CURSOS</b> cadastrados, essa é a fase para adicioná-los.
                Para visualizar as disciplinas cadastradas, selecione um curso. É importante que você informe as disciplinas e cursos que deseja gerar suas provas.
            </p>

            <b>Questões</b>
            <p>
                Nesta fase, você deve escolher o curso, a disciplina e o bimestre que deseja usar para criar suas questões. É importante que você escolha a combinação correta para que as provas sejam criadas de maneira correta e eficiente.
            </p>

            <b>Prova</b>
            <p>
                Por fim, é hora de <b>criar suas provas</b>. O sistema lhe permitirá adicionar questões, escolher o nível de dificuldade, quantidade de questões, e embaralhar as alternativas, garantindo que suas provas sejam únicas e não sejam coladas. Pronto! Agora você já pode começar a usar o nosso sistema para criar suas provas.
            </p>

            <b>Banco de Questões</b>
            <p>
                Aqui você encontrará um banco de dados completo com mais de 50 mil questões de assuntos diferentes para serem utilizadas.
            </p>
        </div>
    );
}

export default HomeContent;