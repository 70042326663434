import { useEffect, useState } from "react";
import api from "../../../services/api";
import Curso from "../../../types/curso.type";
import Component from "../../components";
import { CursoInputProps, cursoSchema } from "../../../schemas/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";


const CursoContent = () => {
    const [listCursos, setListCursos] = useState<Curso[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sucess, setSucess] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<CursoInputProps>({ resolver: zodResolver(cursoSchema) })


    useEffect(() => {
        setLoading(true);
        (async () => {
            await api
                .post("ListarCursos", {
                    usuario: localStorage.getItem("login"),
                    senha:  localStorage.getItem("senha"),
                })
                .then((resp) => {
                    setListCursos(resp.data);
                    setLoading(false);
                });
        })();
    }, []);


const Atualizar = async () =>{
    await api
    .post("ListarCursos", {
        usuario: localStorage.getItem("login"),
        senha:  localStorage.getItem("senha"),
    })
    .then((resp) => {
        setListCursos(resp.data);
        setLoading(false);
    });
}

    const onSubmit = async (data: CursoInputProps) => {
        setLoading(true);
        await api
				.post("AdicionarCurso", data)
				.then((resp) => {
					setLoading(false);
                   
                    setSucess(true);
                    setTimeout(() => {
                        setSucess(false);
                        setValue('nome',"");
                        Atualizar();
                    }, 1500);
				})


    }

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return (
        <form className="" onSubmit={handleSubmit(onSubmit)}>
            {loading && <Component.Shared.LoaderComponent overlay />}

            <h5>Cursos Cadastrados</h5>

            <table className="table mt-2 table-bordered">
                <tbody>
                    {listCursos ? listCursos.slice(startIndex, endIndex).map((item, key) => {
                        return (
                            <tr key={item.id}>
                                <td>
                                    {item.nome}
                                </td>
                            </tr>
                        );
                    }) : null}
                </tbody>
            </table>

            <Component.Custom.Pagination totalItems={listCursos.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage} />
            <h5>Criar um novo curso</h5>
            <label htmlFor="name">Nome</label>
            <Component.Shared.TextInputComponent size={16} margin={0} color="black" register={register('nome')} placeholder="* Nome" type="text" />

            {errors.nome ? (
                <Component.Shared.TextComponent value={errors.nome.message} size={12} color='red' margin={0} align='flex-start' />
            ) : null}

            {sucess ? (
                <Component.Shared.TextComponent value={'Registro salvo com sucesso.'} size={12} color='green' margin={0} align='flex-start' />
            ) : null}

            <button className="btn btn-primary mt-2 mb-2" type='submit'>Adicionar</button>

        </form>
    );
}

export default CursoContent;