import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { RegisterInputProps, registerSchema } from "../../schemas/schemas";
import useAuthStore from "../../context/auth.store";
import './index.css';
import Component from '../../components/components';

import { useEffect, useState } from 'react';


const CreateAccount = () => {
    type PlanType = 'Trimestral' | 'Semestral' | 'Anual' | '';
    const [selectedPlan, setSelectedPlan] = useState<PlanType>('Trimestral');
    const { create, error, clearError } = useAuthStore()
    const [loading, setLoading] = useState(false);
    const [sucess, setSucess] = useState(false);

    useEffect(() => {
        setValue('plano', '1');
        clearError();
    }, []);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<RegisterInputProps>({ resolver: zodResolver(registerSchema) })


    const onSubmit = async (data: RegisterInputProps) => {
        setSucess(false);
        setLoading(true);  // Start loading
  
        const errorFromCreate = await create(data);

        setLoading(false);  // Start loading

        if (errorFromCreate == '') {
            setSucess(true);
            clearError();
        }
    }
    const setPlan = (plan: PlanType, value: string) => {
        setSelectedPlan(plan);
        setValue('plano', value);
    }
    return (
        <div className='main-create'>
            <div className="centered-content">
                {loading && <Component.Shared.LoaderComponent overlay />}
                <form className="form-control contentLogin-create" onSubmit={handleSubmit(onSubmit)}>
                    <Component.Shared.ImageComponent height={150} width={150} className='' source={process.env.PUBLIC_URL + '/logo.png'} margin={10} />

                    <Component.Shared.TextInputComponent size={16} margin={10} color="black" placeholder="* Nome" type="text" register={register('nome')} />
                    {errors.nome ? (
                        <Component.Shared.TextComponent value={errors.nome.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}


                    <Component.Shared.TextInputComponent  margin={10}  mask='cpf' maxLength={14} size={16} color="black" placeholder="* CPF"  type="text" register={register('cpf', { required: 'CPF é obrigatório' })} />
                    {errors.cpf ? (<Component.Shared.TextComponent value={errors.cpf.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextInputComponent   margin={10} size={16} color="black" maxLength={15} mask='telefone' placeholder="* Telefone com DDD" type="text" register={register('telefone')} />
                    {errors.telefone ? (
                        <Component.Shared.TextComponent value={errors.telefone.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextComponent value="DADOS DO PLANO" size={14} color='black' margin={10} align='center' />


                    <input type="radio" value="1" {...register('plano')} hidden />
                    <input type="radio" value="2" {...register('plano')} hidden />
                    <input type="radio" value="3" {...register('plano')} hidden />

                    <div className="plan-switcher">
                        <button type="button"
                            onClick={() => setPlan('Trimestral', '1')}
                            className={selectedPlan === 'Trimestral' ? 'selected' : ''}
                        >
                            Trimestral
                        </button>
                        <button type="button"
                            onClick={() => setPlan('Semestral', '2')}
                            className={selectedPlan === 'Semestral' ? 'selected' : ''}
                        >
                            Semestral
                        </button>
                        <button type="button"
                             onClick={() => setPlan('Anual', '3')}
                            className={selectedPlan === 'Anual' ? 'selected' : ''}
                        >
                            Anual
                        </button>
                    </div>

                    <div className={`box-plan ${selectedPlan !== 'Anual' ? 'gray' : 'destaque'}`}>
                        {selectedPlan === 'Trimestral' && (
                            <>
                                <p className={`ribbon gray`}>
                                    Melhor custo benefício
                                </p>
                                <h2>
                                    <span className="currency">R$</span>
                                    <span className="value">89</span>
                                    <span className="per-month">/mês</span>
                                </h2>
                                <Component.Shared.TextComponent value="1 mês gratuito para novos usuários" size={10} color='gray' margin={0} align='center' />
                            </>
                        )}

                        {selectedPlan === 'Semestral' && (
                            <>
                                <p className={`ribbon gray`}>
                                    Melhor custo benefício
                                </p>
                                <h2>
                                    <span className="currency">R$</span>
                                    <span className="value">69</span>
                                    <span className="per-month">/mês</span>
                                </h2>
                                <Component.Shared.TextComponent value="1 mês gratuito para novos usuários" size={10} color='gray' margin={0} align='center' />
                            </>
                        )}

                        {selectedPlan === 'Anual' && (
                            <>
                                <p className={`ribbon`}>
                                    Melhor custo benefício
                                </p>
                                <h2>
                                    <span className="currency">R$</span>
                                    <span className="value">49</span>
                                    <span className="per-month">/mês</span>
                                </h2>
                                <Component.Shared.TextComponent value="1 mês gratuito para novos usuários" size={10} color='gray' margin={0} align='center' />
                            </>
                        )}
                    </div>
                    {errors.plano? (
                        <Component.Shared.TextComponent value={errors.plano.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextComponent value="DADOS DE ACESSO" size={14} color='black' margin={10} align='center' />

                    <Component.Shared.TextInputComponent size={16} color="black" placeholder="* E-mail" type="text" register={register('email')} />
                    {errors.email ? (
                        <Component.Shared.TextComponent value={errors.email.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextInputComponent margin={10}  size={16} color="black" placeholder="* Usuário" type="text" register={register('usuario')} />
                    {errors.usuario ? (
                        <Component.Shared.TextComponent value={errors.usuario.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}


                    <Component.Shared.TextInputComponent size={16} margin={10}  color="black" placeholder="* Senha" type="password" register={register('senha')} />
                    {errors.senha ? (
                        <Component.Shared.TextComponent value={errors.senha.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextInputComponent margin={10}  size={16} color="black" placeholder="* Confirmação de senha" type="password" register={register('confirmacao_senha')} />
                    {errors.confirmacao_senha ? (
                        <Component.Shared.TextComponent value={errors.confirmacao_senha.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    {error ? (
                        <Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    {sucess ? (
                        <Component.Shared.TextComponent value={'Registro salvo com sucesso.'} size={12} color='green' margin={0} align='flex-start' />
                    ) : null}
                    <button className="form-control btn btn-primary button" type='submit'>Solicitar acesso</button>

                </form>

            </div >
            <div className="footer-sistema-create">
                <p>Inovando Sistemas - Todos os direitos reservados.</p>
            </div>
        </div >

    )
}

export default CreateAccount;


