import { useEffect, useState } from "react";
import api from "../../../services/api";
import Curso from "../../../types/curso.type";
import Disciplina from "../../../types/disciplina.type";
import { QuestoesInputProps, disciplinaSchemaOnly, questoesSchema } from "../../../schemas/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import Assunto from "../../../types/assunto.type";
import Questao from "../../../types/questao.type";
import lstDificuldade from "../../../types/dificuldade.type";
import "./index.css";
import Component from "../../components";

const BancoQuestoesContent = () => {
    const [listCursos, setListCursos] = useState<Curso[]>([]);
    const [curso, setCurso] = useState(0);

    const [listDisp, setListDisp] = useState<Disciplina[]>([]);
    const [listAssuntos, setListAssuntos] = useState<Assunto[]>([]);
    const [listQuestoes, setListQuestoes] = useState<Questao[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);

    const [disciplinaId, setDisciplinaId] = useState<number | null>(null);
    const [isDisciplinaValid, setIsDisciplinaValid] = useState<boolean>(false);


    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<QuestoesInputProps>({ resolver: zodResolver(questoesSchema) })



    useEffect(() => {
        setLoading(true);
        (async () => {
            await api
                .post("ListarCursos", {
                    usuario: localStorage.getItem("login"),
                    senha: localStorage.getItem("senha"),
                })
                .then((resp) => {
                    setListCursos(resp.data);
                    setLoading(false);
                });
        })();
    }, []);

    // Quando disciplinaId mudar, valide-o
    useEffect(() => {
        const result = disciplinaSchemaOnly.safeParse({ disciplinaId });
        setIsDisciplinaValid(result.success);
    }, [disciplinaId]);


    const trocaCurso = async (id: string) => {
        setLoading(true);

        let curso = Number(id);
        console.log(curso);
        api
            .post("ListarDisciplinasC", {
                CursoId: curso,
                login: localStorage.getItem("login"),
                senha: localStorage.getItem("senha"),
            })
            .then((resp) => {
                setListDisp(resp.data);


            });

        api
            .post("ListarAssuntos", {
                id: curso
            })
            .then((resp) => {

                setListAssuntos(resp.data);
                setLoading(false);
            });

        setCurso(curso);
        setDisciplinaId(null);
    }


    const trocaAssunto = async (value: string) => {

        setLoading(true);
        api
            .post("ListarQuestoesWebsite", { descricao: value, CursoId: curso, DisciplinaId: disciplinaId }
            )
            .then((resp) => {
                setListQuestoes(resp.data);
                setLoading(false);
            }); /**salvamos dentro da lista as requisições */


    };

    const handleQuestaoAdded = (addedQuest: Questao) => {

        setListQuestoes(prevQuestoes => prevQuestoes.filter(quest => quest.id !== addedQuest.id));
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return (

        <div>
            {loading && <Component.Shared.LoaderComponent overlay />}
            <div className="form-group">
                <label htmlFor="name2">Curso</label>
                <select
                    defaultValue=""

                    onChange={(e) => trocaCurso(e.target.value)}
                    className="form-control"
                >
                    <option value="" disabled>
                        Selecione um curso
                    </option>
                    {listCursos ? listCursos.map((item, i) => {
                        return (
                            <option
                                key={item.id.toString()}
                                value={item.id}

                            >
                                {item.nome}{" "}
                            </option>
                        );
                    }) : null}
                </select>


                <label htmlFor="name2">Disciplina</label>
                <select
                    defaultValue=""
                    value={disciplinaId || ""}
                    onChange={(e) => setDisciplinaId(Number(e.target.value))}
                    className="form-control"
                >
                    <option value="" disabled>
                        {listDisp.length === 0 ? 'Não existem disciplinas para esse curso' : 'Selecione uma disciplina'}
                    </option>
                    {listDisp.map((item, i) => {
                        return (
                            <option
                                key={item.id.toString()}
                                value={item.id}

                            >
                                {item.nome}{" "}
                            </option>
                        );
                    })}
                </select>
                {!isDisciplinaValid ? (
                    <Component.Shared.TextComponent value={'Esse campo é obrigatório'} size={12} color='red' margin={0} align='flex-start' />
                ) : null}


            </div>
            <hr
                style={{
                    background: "black",
                    color: "black",
                    borderColor: "black",
                    height: "1px",
                }}
            />
            <div className="form-group">
                <label htmlFor="name2">Assunto</label>
                <select

                    defaultValue=""
                    onChange={(e) => trocaAssunto(e.target.value)}
                    className="form-control"
                >
                    <option value="" disabled>
                        {listAssuntos.length === 0 ? 'Não existem assuntos para esse curso' : 'Selecione um assunto'}
                    </option>
                    {isDisciplinaValid && listAssuntos.map((item, i) => {
                        return (
                            <option
                                key={item.id.toString()}
                                value={item.descricao}
                                id={item.descricao.toString()}
                            >
                                {item.descricao}{" "}
                            </option>
                        );
                    })}
                </select>
            </div>

            <table className="table-max">
                <thead>
                    <tr>
                        <th>Questão</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isDisciplinaValid && listQuestoes.slice(startIndex, endIndex)
                            .map((quest, index) => {
                                return <Component.Custom.QuestaoItem key={quest.id} quest={quest} lstDificuldade={lstDificuldade} disciplinaId={disciplinaId} onQuestaoAdded={handleQuestaoAdded} />
                            })}
                </tbody>
            </table>

            <Component.Custom.Pagination totalItems={listQuestoes.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage} />
        </div>
    );
}

export default BancoQuestoesContent;