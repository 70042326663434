import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link, useNavigate } from "react-router-dom";
import { LoginInputProps, loginSchema } from "../../schemas/schemas"
import './index.css';
import Component from '../../components/components';
import { useState, useEffect } from 'react';
import useAuthStore from '../../context/auth.store';

const Login = () => {

  const { login, error, clearError } = useAuthStore()
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue

  } = useForm<LoginInputProps>({ resolver: zodResolver(loginSchema) })



  useEffect(() => {
    // Reseta o erro ao montar a tela novamente

    clearError();
  }, []);

  const onSubmit = async (data: LoginInputProps) => {
    setLoading(true);  // Start loading

    const auth = await login(data);

    if (auth) {
      clearError();
      navigate('/home')
      console.log('adc');
    }

    setLoading(false);  // Finished loading

  };


  return (
    <div className='main'>
      <div className="centered-content">
        {loading && <Component.Shared.LoaderComponent overlay />}
        <form className="form-control contentLogin" onSubmit={handleSubmit(onSubmit)}>
          <Component.Shared.ImageComponent height={150} width={150} className='' source={process.env.PUBLIC_URL + '/logo.png'} margin={10} />
          <Component.Shared.TextInputComponent
            onChange={(e) => setValue('login',e.target.value)} 
             size={16} margin={10} color="black" placeholder="Login" type="text" register={register('login')} />

          {errors.login ? (
            <Component.Shared.TextComponent value={errors.login.message} size={12} color='red' margin={0} align='flex-start' />

          ) : null}
          <Component.Shared.TextInputComponent   onChange={(e) => setValue('senha',e.target.value)}  margin={10} size={16} color="black" placeholder="Senha" type="password" register={register('senha')} />
          
          {errors.senha ? (

            <Component.Shared.TextComponent value={errors.senha.message} size={12} color='red' margin={0} align='flex-start' />
          ) : null}
          {error ? (
            <Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='flex-start' />
          ) : null}

          <button className="form-control btn btn-primary button" type='submit'>Acessar</button>

          <div className='bottom'>
            <Link
              to={'/forgot-password'}
              className='nav-link'>
              Esqueci minha senha
            </Link>
            <Link
              to={'/create-account'}
              className='nav-link'>
              Criar minha conta
            </Link>
          </div>

        </form>
      </div>
      <div className="footer-sistema">
        <p>Inovando Sistemas - Todos os direitos reservados.</p>
      </div>
    </div>

  )
}

export default Login


