import './index.css';

const FooterComponent = () => {
    return (
        <div className="footer-sistema-main">
            <p>Inovando Sistemas - Todos os direitos reservados.</p>
        </div>
    );
}

export default FooterComponent;