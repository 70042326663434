
export interface Criterio {
  id: number;
  descricao: string;
 
}

const list: Criterio[] = [
  {
    id: 1, descricao: "Por nível de dificuldade (questões difíceis)"
   },
   {
    id: 2, descricao: "Por tipo de questão"
   },
   {
    id: 3, descricao: "Sem pontuação"
   },
];

export default list;
