import { useEffect, useState } from "react";
import Instituicao from "../../../types/instituicao.type";
import lstUfs from "../../../types/uf.type";
import api from "../../../services/api";

import Municipio from "../../../types/municipio.type";
import { QuestoesInputProps, questoesSchema } from "../../../schemas/schemas";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Component from "../../components";
import Curso from "../../../types/curso.type";
import Disciplina from "../../../types/disciplina.type";
import Resposta from "../../../types/resposta.type";
import lstDificuldade from "../../../types/dificuldade.type";
import lstTipo from "../../../types/tipoquestao.type";
import Questao from "../../../types/questao.type";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import "./index.css";
const QuestaoContent = () => {
    const [listInst, setListInst] = useState<Instituicao[]>();
    const [desabilitar, setDesabilitar] = useState(false);
    const [desabilitarBotao, setDesabilitarBotao] = useState(true);
    const [listMun, setListMun] = useState<Municipio[]>();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [sucess, setSucess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listCursos, setListCursos] = useState<Curso[]>([]);
    const [listDisp, setListDisp] = useState<Disciplina[]>([]);
    const [listResposta, setListResposta] = useState<Resposta[]>([]);
    const [listQuestoes, setListQuestoes] = useState<Questao[]>([]);
    const [novaResposta, setNovaResposta] = useState<Resposta>({ correta: false, texto: '', alternativa: '', num_resposta: 0, DisciplinaId: 0, num_questao: 0 })
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [tipoQuestao, setTipoQuestao] = useState(false);


    const [error, setError] = useState('');
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<QuestoesInputProps>({ resolver: zodResolver(questoesSchema) })

    useEffect(() => {
        setLoading(true);
        (async () => {

            await api
                .post("ListarCursos", {
                    login: localStorage.getItem("login"),
                    senha: localStorage.getItem("senha"),
                })
                .then((resp) => {
                    setListCursos(resp.data);
                    setLoading(false);
                });
        })();
    }, []);


    const existeAlternativaCorreta = () => {
        return listResposta.some(resposta => resposta.correta);
    }


    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const onSubmit = async (data: QuestoesInputProps) => {
        setLoading(true);
         
        const isTrueSet = (data.tipo.toString() === 'true');
        setTipoQuestao(isTrueSet);

        const updatedData = {

            ...data,
            cursoid: Number(data.cursoid),
            DisciplinaId: Number(data.disciplinaid),
            tipo: isTrueSet,
            nivel: Number(data.nivel),
            bimestre: Number(data.bimestre),
            num_questao: Number(data.num_questao ?? 0),
            list: listResposta
        };


        const formData = new FormData();
        formData.append(
            "data",
            JSON.stringify(updatedData)
        );

        if (selectedFile != null) {
            formData.append(
                "file",
                selectedFile,
                selectedFile.name
            );
        }

      
        if (isTrueSet === true && novaResposta.texto) {
            setError("Existe uma alternativa que não foi adicionada. Clique em + e depois tente salvar novamente");
            setLoading(false);
            return;
        }

        if (isTrueSet === true && (listResposta.length < 2 || !existeAlternativaCorreta())) {
            setError("Obrigatório informar no mínimo duas alternativas e uma correta");
            setLoading(false);
            return;
        }

        const url = data.num_questao
            ? "AlterarQuestaov2"
            : "AdicionarQuestaov2";
        const tipoQuestao = data.num_questao ? "alterar" : "adicionar";
        await api
            .post(url, formData)
            .then((resp) => {
              //  if (tipoQuestao === "adicionar" && updatedData.tipo === true) {
                //    api
                  //      .post("AdicionarRespostas", listResposta)
                    //    .then((resp) => {

                            setLoading(false);
                            setSucess(true);
                            Atualizar();

                            setTimeout(() => {
                                setSucess(false);
                            }, 1500);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
              
         

        setDesabilitar(false);
        limparTudo();
    }


    const Atualizar = async () => {
        api
            .post("ListarQuestoes", {
                DisciplinaId: watch('disciplinaid'),
            })
            .then((resp) => {
                setListQuestoes(resp.data)
                setLoading(false);
            });
    }


    const trocaCurso = async (id: string) => {
        setLoading(true);
        let curso = Number(id);
        api
            .post("ListarDisciplinasC", {
                CursoId: curso,
                login: localStorage.getItem("login"),
                senha: localStorage.getItem("senha"),
            })
            .then((resp) => {
                setListDisp(resp.data);
                setLoading(false);

            });
        setValue('disciplinaid', '');
    }

    const trocaDisciplina = async (id: string) => {
        setValue('disciplinaid', id);
        setLoading(true);
        let value = Number(id);

        api
            .post("ListarQuestoes", {
                DisciplinaId: value,
            })
            .then((resp) => {
                setListQuestoes(resp.data)
                setLoading(false);
            });
    }
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const limparTudo = () => {
        setValue('texto', "");
        setValue("nivel", "");
        setValue("tipo", "");
        setValue("num_questao", "");
        setError('');
        setListResposta([]);
        setDesabilitar(false);
    }

    const handleDelete = async (itemId: number, disciplinaId: number) => {
        setLoading(true);
        try {
            api
                .post("ExcluirQuestao", {
                    num_questao: itemId,
                    disciplinaId: disciplinaId
                })
                .then((resp) => {
                    const listDisp2 = listQuestoes.filter(a => a.num_questao !== itemId);
                    setListQuestoes(listDisp2);
                    setLoading(false);
                    limparTudo();
                });
            // Após a exclusão bem-sucedida, você pode remover o item da lista local
            setListQuestoes(prevList => prevList.filter(item => item.num_questao !== itemId));
        } catch (error) {
            console.error("Erro ao excluir o item:", error);
            // Você pode adicionar mais tratamentos de erro ou mostrar uma mensagem para o usuário aqui
        }
    }


    const handleEditQuestao = async (questao: Questao) => {

        const isTrueSet = (questao.tipo.toString() === 'true');
        setTipoQuestao(isTrueSet);

        setValue('texto', questao.texto);
        setValue('bimestre', questao.bimestre.toString());
        setValue('nivel', questao.nivel.toString());
        setValue('tipo', isTrueSet.toString());
        setValue('num_questao', questao.num_questao.toString());

        
     
        setDesabilitar(true);
        if (isTrueSet === true) {
            api.post("ListarRespostasQuestao", questao).then((resp) => {
                setListResposta(resp.data);
            });
        }

    }


    const handleSaveResposta = (resposta: Resposta) => {



        const num_questao = watch('num_questao');
        const disciplina = watch('disciplinaid');
       
            let list = listResposta;
            resposta.DisciplinaId = Number(disciplina);
            resposta.num_resposta = listResposta.length + 1;
            list.push(resposta);
            setListResposta(list);
      
        setNovaResposta({ correta: false, texto: '', alternativa: '', num_resposta: 0, DisciplinaId: 0, num_questao: 0 });
    }



    const handleChangeTipo = (e: React.ChangeEvent<HTMLSelectElement>) => {

        setValue('tipo', e.target.value);

        const isTrueSet = (e.target.value === 'true');
        setTipoQuestao(isTrueSet);

    };

    const handleRemoveResposta = (resposta: Resposta) => {

        //const num_questao = watch('num_questao');


        //if (num_questao) {

          //  api.post("ExcluirResposta", resposta).then((resp) => {
            //    const lista = listResposta.filter((u) => u !== resposta);
              //  setListResposta(lista);
          //  });
        //}
        //else {

            const lista = listResposta.filter((u) => u !== resposta);
            setListResposta(lista);
        //}

       // console.log(listResposta);
    }
    const handleCheckboxChange = (position: number, event: React.ChangeEvent<HTMLInputElement>) => {
        // Atualizando a lista com novos objetos, com a propriedade 'correta' modificada para o item específico
        const updatedListResposta = listResposta.map((item, index) =>
            index === position
                ? { ...item, correta: event.target.checked }
                : item
        );

        setListResposta(updatedListResposta);
    };

    
    const handleTextChange = (position: number, event: React.ChangeEvent<HTMLInputElement>) => {
        // Atualizando a lista com novos objetos, com a propriedade 'correta' modificada para o item específico
        const updatedListResposta = listResposta.map((item, index) =>
            index === position
                ? { ...item, texto: event.target.value }
                : item
        );

        setListResposta(updatedListResposta);
    };


    return (
        <>
            <form className="" onSubmit={handleSubmit(onSubmit)}>
                {loading && <Component.Shared.LoaderComponent overlay />}
                <h5>Criar uma nova questão</h5>
                <div className="form">
                    <div className="form-group">
                        <label htmlFor="name" >Curso</label>
                        <select
                            defaultValue=""
                            {...register('cursoid')}
                            disabled={desabilitar}
                            className="form-control"
                            onChange={(e) => trocaCurso(e.target.value)}
                        >
                            <option value="" disabled>
                                Selecione um curso
                            </option>
                            {listCursos.map((item, i) => {
                                return (
                                    <option
                                        key={item.id.toString()}
                                        value={item.id}
                                        id={item.id.toString()}
                                    >
                                        {item.nome}{" "}
                                    </option>
                                );
                            })}
                        </select>
                        {errors.cursoid ? (
                            <Component.Shared.TextComponent value={errors.cursoid.message} size={12} color='red' margin={0} align='flex-start' />
                        ) : null}
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Disciplina</label>
                        <select
                            defaultValue=""
                            {...register('disciplinaid')}
                            className="form-control"
                            disabled={desabilitar}
                            onChange={(e) => trocaDisciplina(e.target.value)}
                        >
                            <option value="" disabled>
                                Selecione uma disciplina
                            </option>
                            {listDisp.map((item, i) => {
                                return (
                                    <option
                                        key={item.id.toString()}
                                        value={item.id}
                                        id={item.id.toString()}
                                    >
                                        {item.nome}{" "}
                                    </option>

                                );
                            })}
                        </select>
                        {errors.disciplinaid ? (
                            <Component.Shared.TextComponent value={errors.disciplinaid.message} size={12} color='red' margin={0} align='flex-start' />
                        ) : null}
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Bimestre</label>
                        <input
                            type="number"
                            className="form-control"
                            {...register('bimestre')}
                            placeholder="Informe o bimestre"
                        />
                        {errors.bimestre ? (
                            <Component.Shared.TextComponent value={errors.bimestre.message} size={12} color='red' margin={0} align='flex-start' />
                        ) : null}
                    </div>

                    <div className="border rounded mt-2 p-2" >
                        <br />
                        <div className="row">
                            <div className="col-sm-8">
                                <label htmlFor="name">Questão</label>
                                <textarea
                                    {...register('texto')}
                                    className="form-control"



                                    placeholder="Digite a questão"
                                />
                                {errors.texto ? (
                                    <Component.Shared.TextComponent value={errors.texto.message} size={12} color='red' margin={0} align='flex-start' />
                                ) : null}
                            </div>
                            <div className="col-sm-3">
                                <label htmlFor="name">Tipo</label>
                                <select
                                    className="form-control"
                                    defaultValue=""
                                    disabled={desabilitar}
                                    {...register('tipo')}
                                    onChange={handleChangeTipo}
                                >
                                    <option value="" disabled>
                                        Selecione o tipo
                                    </option>
                                    {lstTipo.map((item, i) => {
                                        return (
                                            <option
                                                key={item.id.toString()}
                                                value={item.id.toString()}
                                            >
                                                {item.descricao}{" "}
                                            </option>
                                        );
                                    })}
                                </select>
                                {errors.tipo ? (
                                    <Component.Shared.TextComponent value={errors.tipo.message} size={12} color='red' margin={0} align='flex-start' />
                                ) : null}
                            </div>
                        </div>
                        <br></br>
                        <label htmlFor="filePicker" className="btn btn-primary mr-1" >
                            Escolha uma imagem
                        </label>
                        <input id="filePicker" style={{ visibility: "hidden" }} type={"file"} onChange={onFileChange}></input>

                        {tipoQuestao === true ?

                            (
                                <table className="table mt-4 ">
                                    <tbody>
                                        {listResposta.map((item, key) => {

                                            return (
                                                <tr key={item.num_resposta}>
                                                    <td className="col-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={item.correta}
                                                            onChange={(e) => handleCheckboxChange(key, e)}
                                                        ></input>{" "}
                                                        <label>Correta</label>
                                                    </td>
                                                    <td className="col-8">
                                                        <input
                                                            type="text"
                                                            value={item.texto}
                                                            onChange={(e) => handleTextChange(key, e)}
                                                            placeholder="Insira uma nova resposta ou deixe em branco"
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td className="col-2">
                                                        <FaPlus onClick={() => desabilitarBotao ? null : handleSaveResposta(item)} type="button" style={{ cursor: desabilitarBotao ? 'default' : 'pointer', opacity: desabilitarBotao ? 0.5 : 1 }} size={20} />
                                                        <FaTrash onClick={() => handleRemoveResposta(item)} type="button" style={{ cursor: 'pointer' }} size={20} />
                                                    </td>
                                                </tr>
                                            );
                                        })}

                                        <tr>
                                            <td className="col-2">
                                                <input
                                                    type="checkbox"
                                                    checked={novaResposta.correta}
                                                    onChange={e => setNovaResposta(prev => ({ ...prev, correta: e.target.checked }))}
                                                ></input>{" "}
                                                <label>Correta</label>
                                            </td>
                                            <td className="col-8">
                                                <input
                                                    type="text"
                                                    value={novaResposta.texto}
                                                    onChange={e => setNovaResposta(prev => ({ ...prev, texto: e.target.value }))}
                                                    placeholder="Insira uma nova resposta ou deixe em branco"
                                                    className="form-control"
                                                />
                                            </td>
                                            <td className="col-2">
                                                <FaPlus onClick={() => handleSaveResposta(novaResposta)} style={{ cursor: 'pointer' }} size={20} />
                                                <FaTrash size={20} />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            ) :
                            (null)}

                        <div className="selectpicker">
                            <label htmlFor="name">Nível de dificuldade</label>
                            <select
                                defaultValue=""
                                {...register('nivel')}
                                className="form-control"
                            >
                                <option value="" disabled>
                                    Selecione uma dificuldade
                                </option>
                                {lstDificuldade.map((item, i) => {
                                    return (
                                        <option
                                            key={item.id.toString()}
                                            value={item.id}
                                            id={item.descricao.toString()}
                                        >
                                            {item.descricao}{" "}
                                        </option>
                                    );
                                })}
                            </select>
                            {errors.nivel ? (
                                <Component.Shared.TextComponent value={errors.nivel.message} size={12} color='red' margin={0} align='flex-start' />
                            ) : null}
                        </div>

                        <br></br>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    {...register('permite_uso')}
                                />{" "}
                                Permitir seu uso para outros professores
                            </label>
                        </div>

                        <div className="row mb-3">
                            <div className="col-12 d-flex justify-content end">
                                <button
                                    className="btn btn-primary mr-1"
                                    type="submit"

                                >
                                    Salvar Questão
                                </button>

                                <button
                                    className="btn btn-secondary"
                                    type="button"
                                    onClick={() => limparTudo()}
                                >
                                    Limpar
                                </button>
                            </div>
                        </div>
                        {sucess ? (
                            <Component.Shared.TextComponent value={'Registro concluído com sucesso.'} size={12} color='green' margin={0} align='flex-start' />
                        ) : null}
                        {error ? (
                            <Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='flex-start' />
                        ) : null}

                    </div>
                </div>
            </form>
            <h5>Questões cadastradas da disciplina</h5>
            <table className="table table-fixed">
                <thead>
                    <tr>
                        <th className="col-xs-1">Nível</th>
                        <th className="col-xs-7">Questão</th>
                        <th className="col-xs-3">Editar</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        listQuestoes.slice(startIndex, endIndex)
                            .map((quest, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="col-xs-1">{quest.nivelDesc}</td>
                                        <td className="col-xs-7">{quest.texto}</td>
                                        <td className="col-xs-3">
                                            <div className="d-flex">
                                                <FaPen onClick={() => handleEditQuestao(quest)} style={{ cursor: 'pointer' }} />
                                                <FaTrash onClick={() => handleDelete(quest.num_questao, quest.disciplinaId)} style={{ cursor: 'pointer' }} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                </tbody>
            </table>

            <Component.Custom.Pagination totalItems={listQuestoes.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage} />
        </>
    );
}

export default QuestaoContent;