
import BancoQuestoesContent from "./custom/bancoquestoes-content/bancoquestoes-content.component"
import ContentComponent from "./custom/content/content.component"
import CursoContent from "./custom/curso-content/curso-content.component"
import DisciplinaContent from "./custom/disciplina-content/disciplina-content.component"
import FooterComponent from "./custom/footer/footer.component"
import GerarProvaContent from "./custom/gerarprova-content/gerarprova-content.component"
import HeaderComponent from "./custom/header/header.component"
import HomeContent from "./custom/home-content/home-content.component"
import InstituicaoContent from "./custom/instituicao-content/instituicao-content.component"
import PaginationComponent from "./custom/pagination/pagination.components"
import QuestaoContent from "./custom/questao-content/questao-content.component"
import QuestaoItem from "./custom/questao-item.component"

import SiderBarComponent from "./custom/siderbar/siderbar.custom.component"
import SideBarMobile from "./custom/siderbarmobile/sidebarmobile.custom.component"
import ImageComponent from "./shared/image.shared.component"
import LoaderComponent from "./shared/loader.shared.component"
import TextInputComponent from "./shared/text.input.component"
import TextComponent from "./shared/text.shared.component"

const Component = {
    Custom: {
     SiderBarComponent: SiderBarComponent,
     HeaderComponent: HeaderComponent,
     FooterComponent: FooterComponent,
     ContentComponent: ContentComponent,
     HomeContent: HomeContent,
     InstituicaoContent: InstituicaoContent,
     CursoContent: CursoContent,
     DisciplinaContent: DisciplinaContent,
     GerarProvaContent: GerarProvaContent,
     BancoQuestoesContent: BancoQuestoesContent,
     SideBarMobile: SideBarMobile,
     Pagination: PaginationComponent,
     QuestaoContent: QuestaoContent,
     QuestaoItem: QuestaoItem
    },
    Layout: {
        
    },
    Shared: {
        LoaderComponent: LoaderComponent,
        TextComponent: TextComponent,
        TextInputComponent: TextInputComponent,
        ImageComponent: ImageComponent
    }
}

export default Component