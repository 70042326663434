
export interface Dificuldade {
    id: number;
    descricao: string;



}


const list: Dificuldade[] = [
    {
        id: 1, descricao: "Fácil"
    },
    {
        id: 2, descricao: "Médio"
    },
     {
        id: 3, descricao: "Difícil"
    }];


export default list;