import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ForgotInputProps, forgotSchema } from "../../schemas/schemas";
import useAuthStore from "../../context/auth.store";
import Component from '../../components/components';
import { useEffect, useState } from 'react';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const { forgot, error, clearError } = useAuthStore()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgotInputProps>({ resolver: zodResolver(forgotSchema) })

  useEffect(() => {
    // Reseta o erro ao montar a tela novamente
   
    
}, []);


  const onSubmit = async (data: ForgotInputProps) => {
    setLoading(true);  // Start loading

    const auth = await forgot(data);
    if (auth) {
      clearError();
      alert("Consulte sua caixa de entrada para receber usuário e senha")
    }

    setLoading(false);  // Start loading
   
  }

  return (
    <div className='main'>
      <div className="centered-content">
        {loading && <Component.Shared.LoaderComponent overlay />}
        <form className="form-control contentLogin" onSubmit={handleSubmit(onSubmit)}>
          <Component.Shared.ImageComponent height={150} width={150} className='' source={process.env.PUBLIC_URL + '/logo.png'} margin={10} />

          <Component.Shared.TextInputComponent margin={10}  size={16} color="black" placeholder="E-mail" type="text" register={register('email')} />

          {errors.email ? (
            <Component.Shared.TextComponent value={errors.email.message} size={12} color='red' margin={0} align='flex-start' />
          ) : null}


          {error ? (
            <Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='flex-start' />
          ) : null}


          <button className="form-control btn btn-primary button" type='submit'>Recuperar senha</button>



        </form>

      </div>
      <div className="footer-sistema">
        <p>Inovando Sistemas - Todos os direitos reservados.</p>
      </div>
    </div>
  )
}

export default ForgotPassword;


