import React from "react";

interface TextInputProps {
  color: string,
  size: number,
  placeholder: string,
  margin?: number
  type?: string,
  mask?: string,
  maxLength?: number,
  register?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  w?: string;
}
const formatPhoneNumber = (value: string) => {
  const cleaned = ('' + value).replace(/\D/g, ''); // Remove caracteres não numéricos
  const match8Digits = cleaned.match(/^(\d{2})(\d{0,4})(\d{0,4})$/);
  const match9Digits = cleaned.match(/^(\d{2})(\d{0,5})(\d{0,4})$/);

  if (match8Digits) {
    const intlCode = match8Digits[1];
    const main = match8Digits[2];
    const rest = match8Digits[3];
    return `(${intlCode}) ${main}-${rest}`;
  } else if (match9Digits) {
    const intlCode = match9Digits[1];
    const main = match9Digits[2];
    const rest = match9Digits[3];
    return `(${intlCode}) ${main}-${rest}`;
  }
  return value;
};
const formatCPF = (value: string) => {
  const cleaned = ('' + value).replace(/\D/g, ''); // Remove caracteres não numéricos
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})?(\d{0,3})?(\d{0,2})?$/);

  if (match) {
    const part1 = match[1] || '';
    const part2 = match[2] ? `.${match[2]}` : '';
    const part3 = match[3] ? `.${match[3]}` : '';
    const part4 = match[4] ? `-${match[4]}` : '';

    return `${part1}${part2}${part3}${part4}`;
  }
  return value;
};

const formatCNPJ = (value: string) => {
  const cleaned = ('' + value).replace(/\D/g, ''); // Remove caracteres não numéricos
  const match = cleaned.match(/^(\d{0,2})(\d{0,3})?(\d{0,3})?(\d{0,4})?(\d{0,2})?$/);

  if (match) {
    const part1 = match[1] || '';
    const part2 = match[2] ? `.${match[2]}` : '';
    const part3 = match[3] ? `.${match[3]}` : '';
    const part4 = match[4] ? `/${match[4]}` : '';
    const part5 = match[5] ? `-${match[5]}` : '';

    return `${part1}${part2}${part3}${part4}${part5}`;
  }
  return value;
};

const formatCEP = (value: string) => {
  const cleaned = value.replace(/\D/g, ''); // Remove caracteres não numéricos

  if (cleaned.length <= 5) {
    return cleaned;
  } else if (cleaned.length <= 8) {
    const part1 = cleaned.substring(0, 5);
    const part2 = cleaned.substring(5);
    return `${part1}-${part2}`;
  }

  return value;
};

const isValidCEP = (value: string) => /^\d{5}-\d{3}$/.test(value);
const isValidPhoneNumber = (value: string) => /^(\(\d{2}\))\s\d{4,5}-\d{4}$/.test(value);
const isValidCPF = (value: string) => /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value);
const isValidCNPJ = (value: string) => /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(value);

const TextInputComponent: React.FC<TextInputProps> = ({
  color,
  size,
  placeholder,
  margin,
  type,
  mask,
  maxLength,
  register,
  onChange, // Adicionando a prop onChange
  value,
  w
}) => {
  const [capsLockOn, setCapsLockOn] = React.useState(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === "password") {
      setCapsLockOn(e.getModifierState("CapsLock"));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (type === "text" && mask === "telefone") {
      if (!isValidPhoneNumber(value)) {
        e.target.value = formatPhoneNumber(value);
      }
    }
    else if (type === "text" && mask === "cpf") {
      if (!isValidCPF(value)) {
        e.target.value = formatCPF(value);
      }
    }
    else if (type === "text" && mask === "cnpj") {
      if (!isValidCNPJ(value)) {
        e.target.value = formatCNPJ(value);
      }
    }
    else if (type === "text" && mask === "cep") {
      if (!isValidCEP(value)) {
        e.target.value = formatCEP(value);
      }

    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <>
      <input
        placeholder={placeholder}
        type={type ? type : 'text'}
        className={`form-control ${w ? w : ''}`}
        value={value}
        onKeyDown={handleKeyDown}
        maxLength={maxLength ? maxLength : 200} // limite de caracteres com formatação
        style={{
          color: color,
          fontSize: size,
          margin: margin ? margin : 0
        }}
        {...register}
        onChange={handleInputChange} // Use handleInputChange aqui
      >
      </input>
      {capsLockOn && type === "password" && (
        <div style={{ color: "black", fontSize: 12, margin: 0}}>Caps Lock está ativado. Isso pode fazer com que você digite a senha incorretamente.</div>
      )}
    </>
  );
}

export default TextInputComponent;