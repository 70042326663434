import React from 'react';
import styled, { keyframes } from 'styled-components';

interface LoaderProps {
  overlay?: boolean;
}
const LoaderContainer = styled.div<LoaderProps>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.overlay ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
  position: fixed; // Mantemos a posição como 'absolute' em todos os casos
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;


const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderCircle = styled.div`
  width: 48px;
  height: 48px;
  border: 5px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: flex;
  box-sizing: border-box;
  animation: ${rotation} 1s linear infinite;
`;

const Loader: React.FC<LoaderProps> = ({ overlay = false }) => (
  <LoaderContainer overlay={overlay}>
    <LoaderCircle />
  </LoaderContainer>
);

export default Loader;