import { z } from 'zod'
import { isValidCPF } from './validations'
export const registerSchema = z
  .object({
    email: z.string().
      email({
        message: "Por favor, insira um e-mail válido",
      }).min(1, 'Este campo é obrigatório'),
    senha: z.string().min(6, 'Este campo é obrigatório'),
    nome: z.string().min(3, 'Este campo é obrigatório'),
    telefone: z.string().min(14, 'Este campo é obrigatório'),
    cpf: z.string().min(14, "Este campo é obrigatório"),
    usuario: z.string().min(6, 'Este campo é obrigatório'),
    plano: z.string(),
    confirmacao_senha: z.string().min(6, 'Este campo é obrigatório'),
  })
  .refine(data => data.senha === data.confirmacao_senha, {
    message: 'Senhas não conferem',
    path: ['password_confirmation']
  })
  .refine(data => isValidCPF(data.cpf), {
    message: "CPF inválido.",
    path: ['cpf']
  });
export type RegisterInputProps = z.infer<typeof registerSchema>

export const loginSchema = z.object({
  login: z.string().min(3, 'Este campo é obrigatório'),
  senha: z.string().min(3, 'Este campo é obrigatório')
})

export type LoginInputProps = z.infer<typeof loginSchema>

export const forgotSchema = z.object({
  email: z.string().
    email({
      message: "Por favor, insira um e-mail válido",
    }).min(1, 'Este campo é obrigatório'),
})

export type ForgotInputProps = z.infer<typeof forgotSchema>


export const recoverySchema = z.object({
  senha: z.string().min(6, 'Este campo é obrigatório'),
  confirmacao_senha: z.string().min(6, 'Este campo é obrigatório'),
})
  .refine(data => data.senha === data.confirmacao_senha, {
    message: 'Senhas não conferem',
    path: ['confirmacao_senha']
  })

export type RecoveryInputProps = z.infer<typeof recoverySchema>



export const instituicaoSchema = z.object({
  nome: z.string().min(3, 'Este campo é obrigatório'),
  MunicipioId: z.string().min(1, 'Este campo é obrigatório'),
})

export type InstituicaoInputProps = z.infer<typeof instituicaoSchema>


export const cursoSchema = z.object({
  nome: z.string().min(3, 'Este campo é obrigatório')
})

export type CursoInputProps = z.infer<typeof cursoSchema>



export const disciplinaSchema = z.object({
  nome: z.string().min(3, 'Este campo é obrigatório'),
  cursoid: z.string().min(1, 'Este campo é obrigatório'),
})

export type DisciplinaInputProps = z.infer<typeof disciplinaSchema>

export const provaSchemaManual = z.object({

  cursoid: z.string().min(1, 'Este campo é obrigatório'),
  instituicaoid: z.string().min(1, 'Este campo é obrigatório'),
  disciplinaid: z.string().min(1, 'Este campo é obrigatório'),
  bimestre: z.string().min(1, 'Este campo é obrigatório'),
  alternativas: z.string().min(1, 'Este campo é obrigatório'),
  nome_prova: z.string(),
  gabarito: z.string().min(1, 'Este campo é obrigatório'),
  pontos: z.string(),
  modelos: z.string().min(1, 'Este campo é obrigatório'),
  criterio: z.string().min(1, 'Este campo é obrigatório'),
  permitir_decimal: z.boolean(),
  diferenciar: z.boolean(),


})
.refine(data => {
  if (data.criterio !== "3" && (!data.pontos || data.pontos.length === 0)) {
    return false;
  }
  return true;
}, {
  message: "Este campo é obrigatório",
  path: ['pontos']
})

export type ProvaManualInputProps = z.infer<typeof provaSchemaManual>

export const provaSchema = z.object({

  cursoid: z.string().min(1, 'Este campo é obrigatório'),
  instituicaoid: z.string().min(1, 'Este campo é obrigatório'),
  disciplinaid: z.string().min(1, 'Este campo é obrigatório'),
  bimestre: z.string().min(1, 'Este campo é obrigatório'),
  alternativas: z.string().min(1, 'Este campo é obrigatório'),
  nome_prova: z.string(),
  gabarito: z.string().min(1, 'Este campo é obrigatório'),
  pontos: z.string().optional(),
  modelos: z.string().min(1, 'Este campo é obrigatório'),
  criterio: z.string().min(1, 'Este campo é obrigatório'),
  permitir_decimal: z.boolean(),
  diferenciar: z.boolean(),
  multiplas: z.string().min(1, 'Este campo é obrigatório'),
  dissertativas: z.string().min(1, 'Este campo é obrigatório'),
  questoesf: z.string().min(1, 'Este campo é obrigatório'),
  questoesm: z.string().min(1, 'Este campo é obrigatório'),
  questoesd: z.string().min(1, 'Este campo é obrigatório'),

})
  .refine(data => {
    if (data.criterio !== "3" && (!data.pontos || data.pontos.length === 0)) {
      
      return false;
    
    }
   
    return true;
  }, {
    message: "Este campo é obrigatório",
    path: ['pontos']
  })

export type ProvaInputProps = z.infer<typeof provaSchema>

export const respostaSchema = z.object({

  correta: z.boolean(),
  texto: z.string().min(1, 'Este campo é obrigatório'),

})

export const questoesSchema = z.object({
  cursoid:  z.string().min(1, 'Este campo é obrigatório'),
  disciplinaid:  z.string().min(1, 'Este campo é obrigatório'),
  nivel: z.string().min(1, 'Este campo é obrigatório'),
  bimestre: z.string().min(1, 'Este campo é obrigatório'),
  tipo: z.string().min(1, 'Este campo é obrigatório'),
  permite_uso: z.boolean().optional(),
  num_questao: z.string().optional(),
  texto: z.string().min(1, 'Este campo é obrigatório'),
})

export type QuestoesInputProps = z.infer<typeof questoesSchema>

export const bancoquestoesSchema = z.object({

  nivel: z.string().min(1, 'Este campo é obrigatório'),
  bimestre: z.string().min(1, 'Este campo é obrigatório'),
})

export type BancoQuestoesInputProps = z.infer<typeof bancoquestoesSchema>

export const disciplinaSchemaOnly = z.object({
  disciplinaId: z.number().min(1, 'Por favor, selecione uma disciplina.')
});





