
export interface Gabarito {
    id: number;
    descricao: string;
   
  }
  
  const gabaritos: Gabarito[] = [
    {
      id: 1, descricao: "Não gerar"
     },
     {
      id: 2, descricao: "Gerar"
     },
     {
      id: 3, descricao: "Gerar avulso"
     },
  ];
  
  export default gabaritos;
