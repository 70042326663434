import { zodResolver } from "@hookform/resolvers/zod";
import { ProvaInputProps, ProvaManualInputProps, provaSchema, provaSchemaManual } from "../../../schemas/schemas";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import Disciplina from "../../../types/disciplina.type";
import Curso from "../../../types/curso.type";
import Instituicao from "../../../types/instituicao.type";
import Component from "../../components";
import criterios from "../../../types/criterio.type";
import gabaritos from "../../../types/gabarito.type";
import Questao from "../../../types/questao.type";

const GerarProvaContent = () => {

    const [listDisp, setListDisp] = useState<Disciplina[]>([]);
    const [listCursos, setListCursos] = useState<Curso[]>([]);
    const [listInst, setListInst] = useState<Instituicao[]>();
    const [loading, setLoading] = useState(false);
    const [isManual, setIsManual] = useState(false);
    const [listQuestoes, setListQuestoes] = useState<Questao[]>([]);
    const [curso, setCurso] = useState(0);
    const [lista, setLista] = useState<number[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>("");

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<ProvaInputProps>({
        resolver: isManual
            ? zodResolver(provaSchemaManual)
            : zodResolver(provaSchema),
    })


    useEffect(() => {
        setLoading(true);
        (async () => {
            await api
                .post("ListarCursos", {
                    login: localStorage.getItem("login"),
                    senha: localStorage.getItem("senha"),
                })
                .then((resp) => {
                    setListCursos(resp.data);
                });

            await api
                .post("ListarInstituicoes", {
                    login: localStorage.getItem("login"),
                    senha: localStorage.getItem("senha"),
                })
                .then((resp) => {
                    setListInst(resp.data);
                    setLoading(false);
                });
        })();
    }, []);

    const criterioValue = watch("criterio");
    useEffect(() => {
        if (isManual) {
            // Se isManual for verdadeiro, faça a chamada axios.post para obter listQuestoes

            const disciplinaId = watch("disciplinaid");

            if (disciplinaId !== "") {


                let bimestre = watch("bimestre");

                if (bimestre === "") {
                    bimestre = "0";
                }

                api.post("ListarQuestoesDisciplinaBimestre",
                    {
                        login: localStorage.getItem("login"),
                        senha: localStorage.getItem("senha"),
                        DisciplinaId: disciplinaId,
                        bimestre: bimestre
                    })
                    .then((response) => {
                        setListQuestoes(response.data);
                    })
                    .catch((error) => {
                        console.error("Erro ao buscar questões:", error);
                    });
            } else {
                // Se isManual for falso, limpe listQuestoes
                setListQuestoes([]);
            }
        }
        else {
            setListQuestoes([]);
        }
    }, [isManual]);

    const trocaCurso = async (id: string) => {
        setLoading(true);
        let curso = Number(id);

        api
            .post("ListarDisciplinasC", {
                CursoId: curso,
                login: localStorage.getItem("login"),
                senha: localStorage.getItem("senha"),
            })
            .then((resp) => {
                setListDisp(resp.data);
                setLoading(false);


            });
        setCurso(curso);

    }

    const trocaInstituicao = async (id: string) => {
        setLoading(true);
        let curso = Number(id);
        console.log(curso);
        api
            .post("ListarDisciplinasC", {
                CursoId: curso,
                login: localStorage.getItem("login"),
                senha: localStorage.getItem("senha"),
            })
            .then((resp) => {
                setListDisp(resp.data);
                setLoading(false);
            });
        setCurso(curso);

    }



    const onSubmit = async (data: ProvaInputProps | ProvaManualInputProps) => {
        setLoading(true);

        let pontos = watch("pontos");

        if (pontos === "") {
            pontos = "0";
        }

        if (isManual) {

            const updatedData = {
                ...data,
                lstQuestoes: lista,
                pontos: pontos
            };


            api
                .post(
                    "SendFilePdf2",
                    updatedData,
                    {
                        responseType: "blob"
                    },
                )
                .then((response) => {
                    setLoading(false);
                    const url = URL.createObjectURL(response.data);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = "document.pdf";
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    // Erro na solicitação

                    if (error.response && error.response.status === 400) {

                        error.response.data.text().then((errorMessage: string) => {
                            const parsedErrorMessage = JSON.parse(errorMessage);

                        });

                    } else {
                        // Outro tipo de erro, faça o tratamento apropriado
                        console.log("Erro desconhecido:", error);
                    }
                    setLoading(false);
                });
        }
        else {

            const updatedData = {
                ...data,
                lstQuestoes: lista,
                pontos: pontos
            };

            api
                .post(
                    "SendFilePdf2",
                    updatedData,
                    {
                        responseType: "blob"
                    },
                )
                .then((response) => {
                    setLoading(false);
                    const url = URL.createObjectURL(response.data);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = "document.pdf";
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    // Erro na solicitação

                    if (error.response && error.response.status === 400) {

                        error.response.data.text().then((errorMessage: string) => {
                            const parsedErrorMessage = JSON.parse(errorMessage);

                        });

                    } else {
                        // Outro tipo de erro, faça o tratamento apropriado
                        console.log("Erro desconhecido:", error);
                    }
                    setLoading(false);
                });
        }

    }

    const marcarQuestao = (quest: Questao) => {
        let verif = lista.filter((item) => item === quest.num_questao);
        const qtde = verif.length;

        if (qtde > 0) {
            const list = lista.filter((u) => u !== quest.num_questao);
            setLista(list)
        } else {
            const list = [...lista, quest.num_questao];
            setLista(list)
        }

    };


    return (
        <form className="" onSubmit={handleSubmit(onSubmit)}>
            {loading && <Component.Shared.LoaderComponent overlay />}
            <div className="form-group">
                <label htmlFor="name2">Instituição</label>
                <select
                    defaultValue=""
                    {...register('instituicaoid')}
                    onChange={(e) => trocaInstituicao(e.target.value)}
                    className="form-control"
                >
                    <option value="" disabled>
                        Selecione uma instituição
                    </option>
                    {listInst ? listInst.map((item, i) => {
                        return (
                            <option
                                key={item.id.toString()}
                                value={item.id}

                            >
                                {item.nome}{" "}
                            </option>
                        );
                    }) : null}
                </select>
                {errors.instituicaoid ? (
                    <Component.Shared.TextComponent value={errors.instituicaoid.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}
            </div>
            <div className="form-group">
                <label htmlFor="name2">Curso</label>
                <select
                    defaultValue=""
                    {...register('cursoid')}
                    onChange={(e) => trocaCurso(e.target.value)}
                    className="form-control"
                >
                    <option value="" disabled>
                        Selecione um curso
                    </option>
                    {listCursos ? listCursos.map((item, i) => {
                        return (
                            <option
                                key={item.id.toString()}
                                value={item.id}

                            >
                                {item.nome}{" "}
                            </option>
                        );
                    }) : null}
                </select>
                {errors.cursoid ? (
                    <Component.Shared.TextComponent value={errors.cursoid.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}
            </div>
            <div className="form-group">
                <label htmlFor="name2">Disciplina</label>
                <select
                    defaultValue=""


                    {...register('disciplinaid')}

                    className="form-control"
                >
                    <option value="" disabled>
                        Selecione uma disciplina
                    </option>
                    {listDisp ? listDisp.map((item, i) => {
                        return (
                            <option
                                key={item.id.toString()}
                                value={item.id}

                            >
                                {item.nome}{" "}
                            </option>
                        );
                    }) : null}
                </select>
                {errors.disciplinaid ? (
                    <Component.Shared.TextComponent value={errors.disciplinaid.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}
            </div>


            <div className="form-group">
                <div className="row">
                    <div className="col-sm-4">
                        <label htmlFor="name">Bimestre</label>
                        <input
                            type="text"
                            className="form-control"

                            {...register('bimestre')}

                            placeholder="Informe o bimestre" />
                        {errors.bimestre ? (
                            <Component.Shared.TextComponent value={errors.bimestre.message} size={12} color='red' margin={0} align='flex-start' />
                        ) : null}
                    </div>
                    <div className="col-sm-4">
                        <label htmlFor="name">Quantidade de alternativas por questão</label>
                        <input
                            type="text"
                            className="form-control"

                            {...register('alternativas')}


                            placeholder="Informe a quantidade" />
                        {errors.alternativas ? (
                            <Component.Shared.TextComponent value={errors.alternativas.message} size={12} color='red' margin={0} align='flex-start' />
                        ) : null}

                    </div>
                    <div className="col-sm-4">
                        <label htmlFor="name">Total de modelos de prova</label>
                        <input
                            type="text"
                            className="form-control"

                            {...register('modelos')}


                            placeholder="Informe a quantidade" />
                        {errors.modelos ? (
                            <Component.Shared.TextComponent value={errors.modelos.message} size={12} color='red' margin={0} align='flex-start' />
                        ) : null}

                    </div>

                </div>
            </div>



            <div className="form-group">
                <label htmlFor="name">Descrição da Prova:</label>
                <input
                    type="text"
                    className="form-control"
                    {...register('nome_prova')}

                    placeholder="B1 , B2, EXAME .." />
            </div>


            <div className="form-group">
                <div className="row">
                    <div className="col-sm-6">
                        <label htmlFor="name">Critério de pontuação da prova</label>
                        <select
                            defaultValue=""
                            {...register('criterio')}
                            className="form-control"
                        >
                            <option value="" disabled>
                                Selecione um critério
                            </option>
                            {criterios.map((item, i) => {
                                return (
                                    <option
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                        id={item.id.toString()}
                                    >
                                        {item.descricao}{" "}
                                    </option>
                                );
                            })}
                        </select>

                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="name">Total de pontos da prova</label>
                        <input
                            type="text"
                            className="form-control"
                            disabled={criterioValue === "3"}
                            {...register('pontos')}

                            placeholder="Informe a quantidade" />
                        {errors.pontos ? (
                            <Component.Shared.TextComponent value={errors.pontos.message} size={12} color='red' margin={0} align='flex-start' />
                        ) : null}

                    </div>
                </div>
            </div>
            <div className="form-group  mb-5">
                <label htmlFor="name">Gabarito</label>
                <select
                    defaultValue=""
                    {...register('gabarito')}

                    className="form-control"
                >
                    <option value="" disabled>
                        Selecione um tipo
                    </option>
                    {gabaritos.map((item, i) => {
                        return (
                            <option
                                key={item.id.toString()}
                                value={item.id}
                                id={item.id.toString()}
                            >
                                {item.descricao}{" "}
                            </option>
                        );
                    })}
                </select>
                {errors.gabarito ? (
                    <Component.Shared.TextComponent value={errors.gabarito.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}
            </div>

            <div className="checkbox">
                <label>
                    <input type="checkbox" onChange={(e) => setIsManual(e.target.checked)} />{" "}
                    Escolher as questões manualmente
                </label>

            </div>
            <div className="checkbox">
                <label>
                    <input
                        type="checkbox"
                        {...register('permitir_decimal')}
                    />{" "}
                    Permitir pontuação em casa decimal (0,5)
                </label>
            </div>
            <div className="checkbox mb-5">
                <label>
                    <input
                        {...register('diferenciar')}
                        type="checkbox"
                    />{" "}
                    Modelos de prova com questões diferentes
                </label>

            </div>

            <hr
                style={{
                    background: "black",
                    color: "black",
                    borderColor: "black",
                    height: "1px",
                }}
            />

            {!isManual ? (
                <><div className="form-group">
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="name">Quantidade de múltipla escolha</label>
                            <input
                                type="number"
                                className="form-control"
                                {...register('multiplas')}
                                placeholder="Informe a quantidade" />
                            {errors.multiplas ? (
                                <Component.Shared.TextComponent value={errors.multiplas.message} size={12} color='red' margin={0} align='flex-start' />
                            ) : null}
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="name">Quantidade de dissertativas</label>
                            <input
                                type="number"
                                className="form-control"
                                {...register('dissertativas')}


                                placeholder="Informe a quantidade" />
                            {errors.dissertativas ? (
                                <Component.Shared.TextComponent value={errors.dissertativas.message} size={12} color='red' margin={0} align='flex-start' />
                            ) : null}
                        </div>
                    </div>
                </div><div className="form-group  mb-4">
                        <div className="row">
                            <div className="col-sm-4">
                                <label htmlFor="name">Quantidade de questões fáceis</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    {...register('questoesf')}
                                    name="questoesf"


                                    placeholder="Informe a quantidade" />
                                {errors.questoesf ? (
                                    <Component.Shared.TextComponent value={errors.questoesf.message} size={12} color='red' margin={0} align='flex-start' />
                                ) : null}

                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="name">Quantidade de questões médias</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    {...register('questoesm')}
                                    name="questoesm"


                                    placeholder="Informe a quantidade" />
                                {errors.questoesm ? (
                                    <Component.Shared.TextComponent value={errors.questoesm.message} size={12} color='red' margin={0} align='flex-start' />
                                ) : null}
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="name">Quantidade de questões difíceis</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    {...register('questoesd')}

                                    name="questoesd"

                                    placeholder="Informe a quantidade" />
                                {errors.questoesd ? (
                                    <Component.Shared.TextComponent value={errors.questoesd.message} size={12} color='red' margin={0} align='flex-start' />
                                ) : null}
                            </div>
                        </div>
                    </div></>

            ) :

                (

                    <table className="table mt-3">
                        <thead>
                            <tr>
                                <th>Ok</th>
                                <th>Questão</th>
                                <th>Nível</th>
                                <th>Bim</th>
                                <th>Tipo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listQuestoes.map((quest, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                onChange={(e) => marcarQuestao(quest)}
                                            ></input>
                                        </td>
                                        <td>{quest.texto}</td>
                                        <td>{quest.nivelDesc}</td>
                                        <td>{quest.bimestre}</td>
                                        <td>{quest.tipo}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )


            }




            <button className="btn btn-primary mt-2 mr-2" type='submit'>Gerar Prova</button>
        </form >
    );
}

export default GerarProvaContent;