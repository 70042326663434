import { Outlet } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";


function App() {
  return (
      <Outlet />
  );
}

export default App