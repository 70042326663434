
import './index.css';

type PaginationProps = {
  totalItems: number,
  currentPage: number,
  setCurrentPage: (page: number) => void,
  itemsPerPage: number,
  setItemsPerPage: (items: number) => void
}


const PaginationComponent = (props: PaginationProps) => {
 
    const totalProducts = props.totalItems;
    const totalPages = Math.ceil(totalProducts / props.itemsPerPage);
  
    const renderPageButtons = () => {
      let buttons = [];
  
      let startPage: number;
      let endPage: number;
  
      if (totalPages <= 5) {
        startPage = 1;
        endPage = totalPages;
      } else if (props.currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (props.currentPage > totalPages - 3) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = props.currentPage - 2;
        endPage = props.currentPage + 2;
      }
  
      for (let i = startPage; i <= endPage; i++) {
        buttons.push(
          <button type={'button'} 
            key={i}
            className={`btn-pagination ${i === props.currentPage ? 'active' : ''}`}
            onClick={() => props.setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
  
      return buttons;
    };
    const handleNext = () => {
      if (props.currentPage < totalPages) props.setCurrentPage(props.currentPage + 1);
    };

    const handlePrev = () => {
      if (props.currentPage > 1) props.setCurrentPage(props.currentPage - 1);
    };


    return(
        <div className="paginacao">
            <button type={'button'}  className="btn-pagination" onClick={handlePrev}>
              Anterior
            </button>

            {renderPageButtons()}

            <button type={'button'} className="btn-pagination" onClick={handleNext}>
              Próximo
            </button>
          </div>
    )


};

export default PaginationComponent;