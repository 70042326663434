import React, { useState } from 'react';
import './index.css';
import { FaBars, FaDatabase, FaFile, FaHome, FaMinus, FaPlus } from 'react-icons/fa';
import Component from '../../components';
import { useNavigate } from 'react-router-dom';

const SideBarMobile: React.FC<{ onMenuItemClick: (title: string) => void }> = ({ onMenuItemClick }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="sidebar-mobile">
      <div className='logo-mobile'>
        <Component.Shared.ImageComponent height={100} width={100} className='' source={process.env.PUBLIC_URL + '/logo.png'} margin={25} />
      </div>
      <div className="hamburger-menu" onClick={() => setMenuOpen(!menuOpen)}>
        <FaBars size={25} />
      </div>
      {menuOpen && (
        <div className="mobile-menu">
          <ul className="menu-list">
            <li className="menu-item" onClick={() => { navigate('/home'); onMenuItemClick('Página Inicial'); setMenuOpen(!menuOpen); }}>
              <FaHome size={15} /> Página Inicial
            </li>

            <li className="menu-item has-submenu">
              <FaPlus size={15} /> Cadastros
              <ul className="submenu-list">
                <li className="submenu-item" onClick={() => { navigate('instituicoes'); onMenuItemClick('Instituições'); setMenuOpen(!menuOpen); }}> <FaMinus size={15} /> Instituições</li>
                <li className="submenu-item" onClick={() => { navigate('cursos'); onMenuItemClick('Cursos'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Cursos</li>
                <li className="submenu-item" onClick={() => { navigate('disciplinas'); onMenuItemClick('Disciplinas'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Disciplinas</li>
                <li className="submenu-item" onClick={() => { navigate('questoes'); onMenuItemClick('Questões'); setMenuOpen(!menuOpen); }}><FaMinus size={15} /> Questões</li>
              </ul>
            </li>

            <li className="menu-item" onClick={() => { navigate('gerarprovas'); onMenuItemClick('Geração de provas'); setMenuOpen(!menuOpen); }}><FaFile size={15} /> Geração de provas</li>
            <li className="menu-item" onClick={() => { navigate('bancoquestoes'); onMenuItemClick('Banco de questões'); setMenuOpen(!menuOpen); }}><FaDatabase size={15} /> Banco de questões</li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default SideBarMobile;