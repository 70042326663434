import { useEffect, useState } from "react";
import Instituicao from "../../../types/instituicao.type";
import lstUfs from "../../../types/uf.type";
import api from "../../../services/api";

import Municipio from "../../../types/municipio.type";
import { InstituicaoInputProps, instituicaoSchema } from "../../../schemas/schemas";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Component from "../../components";


const InstituicaoContent = () => {
    const [listInst, setListInst] = useState<Instituicao[]>();
    const [municipio, setMunicipio] = useState("");
    const [listMun, setListMun] = useState<Municipio[]>();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [sucess, setSucess] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<InstituicaoInputProps>({ resolver: zodResolver(instituicaoSchema) })

    useEffect(() => {
        setLoading(true);
        (async () => {

            await api
                .post("ListarInstituicoes", {
                    login: localStorage.getItem("login"),
                    senha:  localStorage.getItem("senha"),
                })
                .then((resp) => {
                    setListInst(resp.data);
                    setLoading(false);
                });
        })();
    }, []);

    

const Atualizar = async () =>{
    await api
    .post("ListarInstituicoes", {
        login: localStorage.getItem("login"),
        senha:  localStorage.getItem("senha"),
    })
    .then((resp) => {
        setListInst(resp.data);
        setLoading(false);
    });
}

    const trocaUF = async (value: string) => {
        setLoading(true);
        await api
            .post("ListarMunicipios", {
                uf: value,

            })
            .then((resp) => {
                setListMun(resp.data);
                setLoading(false);
            });
    };

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const onSubmit = async (data: InstituicaoInputProps) => {
        setLoading(true);

        const updatedData = {
           id: 0,
           ...data,
           MunicipioId: Number(data.MunicipioId)
        };

        const formData = new FormData();
        formData.append(
            "data",
            JSON.stringify( updatedData )
        );
        if (selectedFile != null) {
            formData.append(
                "file",
                selectedFile,
                selectedFile.name
            );
        }


        await api
            .post("AdicionarInstituicao", formData)
            .then((resp) => {
            
                setLoading(false);

                setSucess(true);
                setTimeout(() => {
                    setSucess(false);
                    setValue('nome',"");
                    Atualizar();
                }, 1500);
            });

    }

    return (
        <>
            <form className="" onSubmit={handleSubmit(onSubmit)}>
                {loading && <Component.Shared.LoaderComponent overlay />}
                <h5>Instituições cadastradas</h5>
                <table className="table mt-2 table-bordered">
                    <tbody>
                        {listInst ? listInst.map((item, key) => {
                            return (
                                <tr key={item.id}>
                                    <td>
                                        {item.nome}
                                    </td>
                                </tr>
                            );
                        }) : null}
                    </tbody>
                </table>

                <h5>Criar uma nova instituição</h5>
                <label htmlFor="name">UF</label>
                <select

                    onChange={(e) => trocaUF(e.target.value)}
                    className="form-control w-25"
                >
                    <option value="" disabled>
                        Selecione uma UF
                    </option>
                    {lstUfs.map((item, i) => {
                        return (
                            <option
                                key={item.uf.toString()}
                                value={item.uf}
                                id={item.uf.toString()}
                            >
                                {item.uf}{" "}
                            </option>
                        );
                    })}
                </select>




                <label htmlFor="name">Município</label>
                <select

                    defaultValue=""
                    {...register('MunicipioId')}
                    onChange={(e) => setMunicipio(e.target.value)}
                    className="form-control"
                >
                    <option value="" disabled>
                        Selecione um município
                    </option>
                    {listMun ? listMun.map((item, i) => {
                        return (
                            <option
                                key={item.id.toString()}
                                value={item.id}
                                id={item.id.toString()}
                            >
                                {item.nome}{" "}
                            </option>
                        );
                    }) : null}
                </select>
                {errors.MunicipioId ? (
                    <Component.Shared.TextComponent value={errors.MunicipioId.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}

                <label htmlFor="name">Nome</label>
                <Component.Shared.TextInputComponent size={16} margin={0} color="black" placeholder="* Nome" type="text" register={register('nome')} />
                {errors.nome ? (
                    <Component.Shared.TextComponent value={errors.nome.message} size={12} color='red' margin={0} align='flex-start' />
                ) : null}

                {sucess ? (
                    <Component.Shared.TextComponent value={'Registro concluído com sucesso.'} size={12} color='green' margin={0} align='flex-start' />
                ) : null}

                <label htmlFor="filePicker" className="btn btn-secondary mt-2 " >
                    Escolha uma logo
                </label>

                <input id="filePicker" style={{ visibility: "hidden" }} type={"file"} onChange={onFileChange}></input>
                <button className="btn btn-primary mt-2 mr-2" type='submit'>Adicionar</button>
            </form>
        </>
    );
}

export default InstituicaoContent;